import { Divider } from '@chakra-ui/react';
import './Footer.css';
import {
  FaTelegramPlane,
  FaTwitter,
  FaDiscord,
  FaInstagram,
  FaMedium,
  FaFacebook
} from "react-icons/fa";

function Footer() {

  return (
    <div>
      <Divider />
      <div className="Footer">
        <div className="leftfooter">
          
        </div>
        <div className="centerfooter">
          
        </div>
        <div className="rightfooter">
          <a
            href="https://discord.gg/drXzmDW4Xu"
            target="_blank"
            rel="noreferrer"
          >
            <FaDiscord className="icon-image" />
          </a>
          <a
            href="https://twitter.com/TheNydo"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter className="icon-image" />
          </a>
          <a href="t.me/nydo_project" target="_blank" rel="noreferrer">
            <FaTelegramPlane className="icon-image" />
          </a>
          <a href="https://www.instagram.com/nydoproject/" target="_blank" rel="noreferrer">
            <FaInstagram className="icon-image" />
          </a>
          <a href="https://www.facebook.com/Nydo-103342455636055" target="_blank" rel="noreferrer">
            <FaFacebook className="icon-image" />
          </a>
          <a href="https://medium.com/@nydo" target="_blank" rel="noreferrer">
            <FaMedium className="icon-image" />
          </a>
        </div>
      </div>
    </div>

  );
}

export default Footer;