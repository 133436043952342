// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from "@chakra-ui/react"

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "dark",
}

// 3. extend the theme
const theme = extendTheme({
  config, 
  colors: {
    brand: {
      100: "#666766",
      200: "#666766",
      300: "#666766",
      400: "#666766",
      500: "#666766",
      600: "#666766",
      700: "#666766",
      800: "#666766",
      900: "#666766",
    },
    brandblue: {
      100: "#7cbcff",
      200: "#7cbcff",
      300: "#7cbcff",
      400: "#7cbcff",
      500: "#7cbcff",
      600: "#7cbcff",
      700: "#7cbcff",
      800: "#7cbcff",
      900: "#7cbcff",
    },
  },
})

export default theme