import { Button, Flex, Progress, Text, Image, useToast } from "@chakra-ui/react";
import { ethers, providers } from "ethers";
import { useEffect, useState } from "react";
import ButtonWithShadow from "../../ChakraCustomComponents/ButtonWithShadow";
import gif from "../../../res/raiderwolvesgif.gif";
import abi from "../../../res/abis/nydoeri.json";
import { keccak256 } from "ethers/lib/utils";
import Countdown, { zeroPad } from 'react-countdown';
import "./Mint.css";

const { MerkleTree } = require('merkletreejs');


function Mint(props: {
    appState: any;
    contract: string;
}) {

    const [getCroBalance, setCroBalance] = useState(0);
    const [getMintAmount, setMintAmount] = useState(1);
    const [mintLive, setMintLive] = useState(true);
    var mintPrice = 100;//?
    const canMint = !mintLive;
    const [totalSupply, setTotalSupply] = useState(0);
    const maxSupply = 2222;
    const mintDate = 1654027200000;


    const addressesWLFM = ["0x11e71c35391c8123B6b411428F9bec8Db1afBE1e", "0x5Cf666E04D6e71783F88698068B224f6fa5b07D2", "0x600A22C630D86889851680E12062B7bA4FFF81Ab", "0xF161E236a50aD2b6A95aCf0D173aB2CcCE3B12ed", "0x96E40cB6A59C9803bcF2ADC06fd83B00AF9af8A3", "0x75056F8e353d6ffE40B1BA07C3Dfce4352910E9C", "0xde1a45aB063f620Ec74da6027d865672e9A6593b", "0x711B24A8C00A44e8db319D14723d8ba2F6D33885", "0xa537243b173b7E8D703878Ce8F357Da2CdDa6F88", "0x5B38Da6a701c568545dCfcB03FcB875f56beddC4"]
    const addressesWLB = ["0x711B24A8C00A44e8db319D14723d8ba2F6D33885", "0x5B38Da6a701c568545dCfcB03FcB875f56beddC4"]

    const leavesWLFM = addressesWLFM.map(x => keccak256(x))
    const leavesWLB = addressesWLB.map(x => keccak256(x))
    const treeWLFM = new MerkleTree(leavesWLFM, keccak256, { sortPairs: true })
    const treeWLB = new MerkleTree(leavesWLB, keccak256, { sortPairs: true })
    const buf2hex = (x: { toString: (arg0: string) => string; }) => '0x' + x.toString('hex')

    const nydoEriReadContract = new ethers.Contract(props.contract, abi, props.appState.cronosProvider);
    const nydoEriWriteContract = new ethers.Contract(props.contract, abi, props.appState.injectedProvider);


    const toastFactory = useToast();
    function NotEnoughCRO() {
        toastFactory({
            title: "Not enough CRO",
            status: "warning",
            duration: 5000,
            isClosable: true,
        })
    }
    function AtLeast1CRO() {
        toastFactory({
            title: "You need at least 1 CRO to pay the fees",
            status: "warning",
            duration: 5000,
            isClosable: true,
        })
    }
    function NotInWL() {
        toastFactory({
            title: "You are not in the whitelist, try again later...",
            status: "error",
            duration: 5000,
            isClosable: true,
        })
    }
    function showTransactionToast(transactionHash: string) {
        toastFactory({
            title: "Transaction completed.",
            description: (
                <>
                    Find your transaction{" "}
                    <a
                        href={`https://cronoscan.com/tx/${transactionHash}`}
                        target={"_blank"}
                        rel="noreferrer"
                        className="txt-underline"
                    >
                        <b>here.</b>
                    </a>
                </>
            ),
            status: "success",
            duration: null,
            isClosable: true,
        });
    }

    function showSentTransactionToast(transactionHash: string) {
        toastFactory({
            title: "Transaction sent.",
            description: (
                <>
                    Find your transaction{" "}
                    <a
                        href={`https://cronoscan.com/tx/${transactionHash}`}
                        target={"_blank"}
                        rel="noreferrer"
                        className="txt-underline"
                    >
                        <b>here.</b>
                    </a>
                </>
            ),
            status: "info",
            duration: 5000,
            isClosable: true,
        });
    }

    function showTransactionErrorToast(error: any) {
        toastFactory({
            title: "Transaction error.",
            description: `There was an error processiong your transaction, ${error.message}`,
            status: "error",
            duration: 10000,
            isClosable: true,
        });
    }
    useEffect(() => {
        if (Date.now() > mintDate) {
            setMintLive(true);
        }
        loadSupply();
        //        setListener();
    }, []);
    useEffect(() => {
        if (props.appState.address !== undefined) {
            getBalance();
        } else {
            setCroBalance(0);
        }
    }, [props.appState.address]);

    const renderer = ({ days, hours, minutes, seconds }: any): JSX.Element => {
        return <span>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }

    function inc() {
        if (getMintAmount < 20) {
            setMintAmount(getMintAmount + 1)
        }
    }
    function dec() {
        if (getMintAmount > 1) {
            setMintAmount(getMintAmount - 1)
        }
    }
    async function getBalance() {
        //Balance
        var bal = ethers.utils.formatEther(await props.appState.injectedProvider.getSigner().getBalance());
        setCroBalance(parseFloat(bal));
    }
    async function loadSupply() {
        var supply = await nydoEriReadContract.totalSupply();
        //if (supply > max_supply) setSoldout(true);
        setTotalSupply(supply.toNumber());
    }
    async function mintFM() {
        if (getCroBalance < 1) return AtLeast1CRO();
        var proof = await treeWLFM.getProof(keccak256(props.appState.address)).map((x: { data: { toString: (arg0: string) => string; }; }) => buf2hex(x.data));
        var isValid = await nydoEriReadContract.isValid(0, proof, keccak256(props.appState.address));
        if (!isValid) return NotInWL();
        try {
            const signerContract = nydoEriWriteContract.connect(props.appState.injectedProvider.getSigner(props.appState.address));
            const res = await signerContract.mintWLFM(proof);
            showSentTransactionToast(res.hash);
            const receipt = await res.wait()
            showTransactionToast(receipt.transactionHash);
            loadSupply();
        } catch (error) {
            showTransactionErrorToast(error);
        }
    }
    async function mintNft() {
        var extra = {
            value: ethers.utils.parseEther((mintPrice*getMintAmount).toString()),
        };
        if (getCroBalance < getMintAmount * mintPrice) return NotEnoughCRO();
        try {
            const signerContract = nydoEriWriteContract.connect(props.appState.injectedProvider.getSigner(props.appState.address));
            const res = await signerContract.mint(getMintAmount, extra);
            showSentTransactionToast(res.hash);
            const receipt = await res.wait();
            showTransactionToast(receipt.transactionHash);
            loadSupply();
            getBalance();
        } catch (error) {
            showTransactionErrorToast(error);
        }
    }


    return (
        <div className="mintcontainer">

            <Flex flexDirection={"column"} align={"center"} minWidth={"100%"}>
                <Text fontSize={"3xl"}>{mintLive ? <>MINT LIVE!</> : <>MINT LIVE IN <Countdown onComplete={() => setMintLive(true)} date={mintDate} renderer={renderer}></Countdown></>}</Text>
                <Progress borderRadius={"full"} colorScheme={"brandblue"} h={"5vh"} w={"50%"} value={totalSupply / maxSupply * 100}></Progress>
            </Flex>
            <Flex gap={"20px"} minW={"400px"} w={"30%"} align={"center"} flexDirection={"column"}>
                <Image minW={"400px"} src={gif} borderRadius={"20%"}></Image>
                <Flex className="amount-cost" justifyContent={"space-evenly"} w={"100%"}>
                    <Flex gap={"20px"} flexDirection={"column"} align={"center"} w={"100%"}>
                        <Text fontSize={"2xl"}>AMOUNT&nbsp;</Text>
                        <Flex w={"100%"} fontSize={"2xl"} justifyContent={"space-between"} alignItems={"center"}>
                            <Button disabled={getMintAmount === 1} onClick={dec}>-</Button>
                            <p>{getMintAmount}</p>
                            <Button disabled={getMintAmount === 20} onClick={inc}>+</Button>
                        </Flex>
                        <Flex w={"100%"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
                            <Text fontSize={"2xl"}>COST  </Text>
                            <Text fontSize={"2xl"}>{mintPrice * getMintAmount} CRO</Text>
                        </Flex>
                    </Flex>
                </Flex>
                {props.appState.address === undefined ?
                    <Flex w={"100%"} textAlign={"center"} justifyContent={"center"}>
                        <Text className="textmobile" fontSize={"2xl"}>CONNECT YOUR WALLET</Text>
                    </Flex>
                    :
                    <>
                        <ButtonWithShadow onClick={mintNft} disabled={canMint}>Mint</ButtonWithShadow>
                    </>
                }
            </Flex>
        </div >
    )
}

export default Mint;