import {
  Stack,
  Flex,
  Text,
  VStack,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import boceto from "../../res/finalhome.png";
export default function CustomHome() {
  return (
    <Flex
      w={'full'}
      backgroundImage={boceto}
      bgRepeat={"no-repeat"}
      backgroundSize={'100%'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}>
        <Image visibility={"hidden"} src={boceto}></Image>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}