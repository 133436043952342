import { Route, Routes } from 'react-router-dom';
import './Main.css';
import Home from './Home/Home';
import Mint from './Mint/Mint';
/* import Address from './Address/Address';
import Collections2d from './Collections2d/Collections2d';
import Collections3d from './Collections3d/Collections3d'; */


const Main = (props: {
  appState: any,
}) => {
  return (
    <div className="Main">
      <Routes>
        <Route path="/" element={<Home appState={props.appState} />} />
        {/* <Route path="/Mint" element={<Mint appState={props.appState} contract={""} />} /> */}
        {/* <Route path="/address/:address" element={<Address appState={props.appState} />} />
        <Route path="/collections2d" element={<Collections2d appState={props.appState}/>}/>
        <Route path="/collections3d" element={<Collections3d appState={props.appState}/>}/>
 */}
      </Routes>
    </div>
  );
}

export default Main;