import './Header.css';
import logo from "../../res/logo.png";
import defiwalletlogo from '../../res/defi.svg';
import { Button, Divider, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Text, useToast } from "@chakra-ui/react"
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { FaWallet, FaUnlink, FaAlignJustify } from 'react-icons/fa';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { DeFiWeb3Connector } from "deficonnect";
import { providers } from 'ethers';
import Blockies from 'react-blockies';
import Web3 from 'web3';


const Header = (props: {
  appState: any
}) => {
  //CONNECTOR STATE
  const [connector, setConnector] = useState<any>(undefined)
  //PROVIDER OPTIONS WEB3 MODAL
  const providerOptions = {
    injected: {
      display: {
        logo: "https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg",
        name: "MetaMask",
        description: "Connect with MetaMask in your browser"
      },
      package: null
    },
    "custom-walletconnect": {
      display: {
        logo: "https://docs.walletconnect.com/img/walletconnect-logo.svg",
        name: "WalletConnect",
        description: "Connect with any WalletConnect compatible wallet."
      },
      options: {
        appName: 'CRO CROW NFT',
        networkUrl: 'https://evm-cronos.crypto.org/',
        chainId: 25
      },
      package: WalletConnectProvider,
      connector: async () => {
        const connector = new WalletConnectProvider({
          rpc: {
            1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
            25: "https://evm-cronos.crypto.org"
          },
          chainId: 25,
          clientMeta: {
            description: 'CRO CROW NFT',
            icons: ['https://crocrow.com/logo.png'],
            name: "CRO CROW NFT",
            url: "https://crocrow.com/"
          }
        });
        await connector.enable();
        setConnector(connector);
        return connector;
      }
    },
    "custom-defiwallet": {
      display: {
        logo: defiwalletlogo,
        name: "Crypto.com DeFi Wallet",
        description: "Connect with DeFi Wallet browser extension or app"
      },
      options: {},
      package: WalletConnectProvider,
      connector: async () => {
        const connector = new DeFiWeb3Connector({
          supportedChainIds: [25],
          rpc: {
            25: "https://evm-cronos.crypto.org/", // cronos mainet
          },

        });

        await connector.activate();
        setConnector(connector);
        let provider = await connector.getProvider();
        return provider;
      }
    },

  }
   useEffect(() => {
    const checkConnection = async () => {

      let web3: any;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
      };

      web3.eth.getAccounts()
        .then(async (addr: string) => {
          if (addr.length !== 0) {
            connect();
          }
        });
    };
    checkConnection();
  }, []);
  useEffect(() => {
    if (props.appState.injectedProvider !== undefined) {
      if (props.appState.injectedProvider.connection.url === "metamask") {
        if (props.appState.injectedProvider.provider.chainId !== "0x19") {//not in cronos
          window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
              chainId: "0x19",
              rpcUrls: ["https://evm-cronos.crypto.org/"],
              chainName: "Cronos Mainnet",
              nativeCurrency: {
                name: "CRONOS",
                symbol: "CRO",
                decimals: 18
              },
              blockExplorerUrls: ["https://cronoscan.com/"]
            }]
          });
        }
      }
    }
  }, [props.appState.address])

    //TOASTS
    const toastFactory = useToast();
    function DisconnectedToast() {
      toastFactory({
        title: "Wallet disconected",
        status: "warning",
        duration: 5000,
        isClosable: true,
      })
    }
    function ConnectedToast() {
      toastFactory({
        title: "Wallet connected",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    }
  //WEB3 MODAL
  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
    theme: {
      background: "rgb(39, 49, 56)",
      main: "rgb(199, 199, 199)",
      secondary: "rgb(136, 136, 136)",
      border: "rgba(195, 195, 195, 0.14)",
      hover: "rgb(245, 26, 32)"
    }
  });

  //NAVIGATE
  const navigate = useNavigate();

  const [searchBarValue, setSearchbarValue] = useState('');
  const onChangeSearchBar = (event: any) => setSearchbarValue(event.target.value);

  function onKeyPressSearchBar(event: any) {
    if (event.key === 'Enter') {

    }
  }

  //CONNECT BUTTON
  async function connect() {
    try {
      const prov = await web3Modal.connect();
      const web3Provider = new providers.Web3Provider(prov);
      props.appState.setInjectedProvider(web3Provider);
      const signer = web3Provider.getSigner();
      const address = (await signer.getAddress()).toLowerCase();
      props.appState.setAddress(address);
      ConnectedToast();
    } catch (error) {
      console.log(error)
    }
  }
  //DISCONNECT BUTTON
  async function disconnect() {
    web3Modal.clearCachedProvider();
    localStorage.clear();

    try {
      connector?.disconnect();
    }
    catch (error) {
    }

    try {
      connector?.deactivate();
    }
    catch (error) {
      console.log(error)
    }
    props.appState.setAddress(undefined);
    props.appState.setInjectedProvider(undefined);
    DisconnectedToast();
  }
  //COMPONENT
  return (
    <div>
      < div className="Header">
        <div className="leftheader">
          <img className="small-image" src={logo} alt="logo" style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
        </div>
        <div className='centerheader'>
          <Input
            value={searchBarValue}
            onChange={onChangeSearchBar}
            placeholder='Search collection'
            onKeyPress={onKeyPressSearchBar}
          />

        </div>
        <div className="rightheader">
          {
            props.appState.injectedProvider === undefined
              ?
              <Button colorScheme="white" aria-label="Connect Wallet" variant={"outline"} onClick={connect} leftIcon={<FaWallet />}>
                <Text isTruncated w="100%" fontWeight={400}>
                  Connect wallet
                </Text>
              </Button>
              :
              <>
                <IconButton
                  onClick={disconnect}
                  isRound
                  colorScheme='gray'
                  aria-label='Call Sage'
                  icon={<FaUnlink />}
                />
                <Button colorScheme="green" aria-label="Wallet Connected" variant={"outline"} className="connected-button"
                  leftIcon={<div style={{ overflow: "hidden", borderRadius: "20%" }}>
                    <Blockies scale={3} seed={props.appState.address ? props.appState.address : "1"} /></div>
                  }>
                  <Text isTruncated w="100%" fontWeight={400}>
                    {props.appState.address ? props.appState.address.substring(0, 6) +
                      "..." +
                      props.appState.address.substring(props.appState.address.length - 4, props.appState.address.length) : "1"}
                  </Text>
                </Button>

              </>
          }
          <Menu isLazy>
            <MenuButton
              as={IconButton}
              aria-label=""
              icon={<FaAlignJustify />} />

            <MenuList>
              <MenuItem>
                <a href="#collections">
                  Collections
                </a>
              </MenuItem>
              <MenuItem >
                <a href="#roadmap">
                  Roadmap
                </a>
              </MenuItem>
              <MenuItem>
                <a href="#faq">
                  FAQ
                </a>
              </MenuItem>
              <MenuItem>
                <a href="#team">
                  Team
                </a>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <Divider />
    </div>

  );
}

export default Header;