import Faq from 'react-faq-component';

export default function CustomFaq() {
    const styles = {
        titleTextColor: "white",
        rowTitleColor: "white",
        rowContentColor: "white",
        bgColor: '#1a202c',
    };
    const data = {
        title: "FAQ",
        rows: [
            {
                title: "What is an NFT",
                content: "An NFT, or Non Fungible Token, is a unique digital asset that holds a specific spot within the Blockchain. It can vary from representing digital art in the form of images, to tracking real-world assets, such as a house or a car. In other words, it is an object or asset that can range from images, GIFs, songs... and that implements a specific function."
            },
            {
                title: "Why are NFTs special",
                content: <p>There are six main properties that make NTFs so special:<br/>
                - Uniqueness: All NFTs are unique and set them apart from others.
                <br/>- Rarity: NFTs are famous because they are scarce. There is a finite number of assets, since each one can only be registered once.
                <br/> - Indivisibility: NFTs cannot be split. For example, you can own portions of a cryptocurrency, but you cannot own portions of an NFT. It would be like owning portions of a car or a train ticket.
                <br/>- Traceability: NFTs hold a register of the different owners/transactions where it has been involved. It helps to check authenticity. 
                <br/>- Utilities: Through smart contracts, NFTs are capable of acquiring different functionalities. Some famous examples can be staking, breeding and burning. All these can allow owners to do things such as generating cryptocurrencies or other kinds of rentability. The limit lies in the blockchain developer's contracts.
                <br/>- Metaverses: Although the concept of Metaverse has existed for several years, it is gaining a lot of relevance nowadays with the expansion of blockchain-based Metaverses. NFTs can be linked to these Metaverses by the programming within the smart contracts. It brings possibilities such as interacting with your assets and showcasing them to the virtual world.</p>
            },
            {
                title: "When will the minting phase be?",
                content: "Someday between May and June, stay tuned!"
            },
            {
                title: "What will the price be?",
                content: "Yet to be confirmed but probably around 30$"
            },{
                title: "How do I know if the money is being sent to the animal shelter?",
                content: "The money will be automatically sent by the contract, as soon as the contract is deployed we will link it here."
            }],
    }
    return (
        <Faq styles={styles} data={data} />
    )
}