import './Home.css';
import { Text, Divider, Image } from "@chakra-ui/react";
import { useNavigate } from 'react-router';
import comingsoon from '../../../res/comingsoon.png';
import nydoxerisatris from '../../../res/nydoxerisatris.png';
import roadmap from '../../../res/roadmap.png';
import CustomHome from '../../ChakraCustomComponents/CustomHome';
import { Stepper } from 'react-form-stepper';
import CustomFaq from '../../ChakraCustomComponents/Faq';
import CustomGrid from '../../ChakraCustomComponents/CustomGrid';
import Mint from '../Mint/Mint';


const Home = (props: {
  appState: any,
}) => {
  const navigate = useNavigate();


  return (
    <div className="Home">
      <CustomHome></CustomHome>
      <div className="description-block">
        <br />
        <b><p className="title-font">The N<span className="special-font">y</span>do Project</p></b>
        <p className="body-font">
          <br />
          Nydo is a platform for designers all around the world. Our mission is to <b><span className="special-font">separate the IT knowledge </span></b> from the NFT dropping process.<br />
          Artists can contact us from anywhere from our social media so we can collaborate to drop a 2D or 3D collection.<br />
          We provide the<b><span className="special-font"> necessary means</span></b> for artists to turn their models and designs into awesome NFT collections.<br /> As soon as the artist has the models ready, we can release the collection that same day.
          <br />
          <br />
          <br />
          We want to emphasize our independence from the artistic process. Nydo's purpose is to assemble any art pieces into a NFT collection for the artist.
          <br />
          For further information on any of our collaborations or our project itself check our <a className='txt-underline' target="_blank" rel="noreferrer" href="https://medium.com/@nydo">medium</a>
          <br />
          
          <br />
          <br />
        </p>
      </div>
      <Divider orientation="horizontal" />
      <Mint appState={props.appState} contract={"0x6aC363B090261dA44A4B4832cA50407caB5895A0"} />
      <a id="collections"></a>
      <Divider orientation="horizontal" />
      <div className='dims2d3d'>
        <div className='dim2d'>
          <a
            target="_blank"
            rel="noreferrer"
            href='https://medium.com/@nydo/raider-wolves-en-d98f3fcc9e0f'>
            <img className='img-2d' alt="2d" src={nydoxerisatris} onClick={() => { }} />
          </a>
        </div>
        <Divider orientation='vertical' />
        <div className='dim3d'>
          <img className='img-2d' alt="3d" src={comingsoon} />
        </div>
      </div>
      <a id="roadmap"></a>
      <Divider orientation='horizontal' />
      <div className='roadmap'>
        <div className='roadmap-flex'>
          <Image className='roadmapimg' src={roadmap}></Image>
          <Divider orientation='vertical' />
          <Text className='roadmap-text' textAlign={"left"}><b><span style={{ color: '#4BB543' }}>Launch</span></b>:
            Creation of all the social networks that form Nydo's communication infrastructure.<br /><br />
            <b><span style={{ color: '#4BB543' }}>Presentation</span></b>:
            The announce of the first collection.<br /><br />
            <b><span style={{ color: '#7cbcff' }}>Community</span></b>:
            This phase encompasses everything that will happen between now and May/2022.<br /><br />
            <b><span style={{ color: '#666766' }}>Mint</span></b>:
            The official date will be announced soon on our social media, though we guarantee it will be in between the month of May and June.<br /><br />
            <b><span style={{ color: '#666766' }}>Post Mint</span></b>: Holder privilegies and ....
          </Text>
        </div>
        <Stepper
          styleConfig={{ activeBgColor: "#7cbcff", activeTextColor: "#ffffff", completedBgColor: "#4BB543", completedTextColor: "#ffffff", inactiveBgColor: "#666766", inactiveTextColor: "#ffffff", size: "2em", circleFontSize: "1rem", labelFontSize: "0.875rem", borderRadius: "50%", fontWeight: "500" }}
          steps={[{ label: 'Launch' }, { label: 'Presentation' }, { label: 'Community' }, { label: 'Mint' }, { label: 'Post Mint' }]}
          activeStep={2}
        >
        </Stepper>
      </div>
      <a id="faq"></a>
      <Divider></Divider>
      <div className='FAQ'>
        <CustomFaq></CustomFaq>
      </div>
      <a id="team"></a>
      <Divider></Divider>
      <CustomGrid></CustomGrid>

    </div>
  );
}

export default Home;