import { ReactNode } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
} from '@chakra-ui/react';
import tobello from "../../res/team/1.png";
import luis from "../../res/team/3.png";
import pablo from "../../res/team/2.png";
import wolf from "../../res/team/4.png";


const Testimonial = ({ children }: { children: ReactNode }) => {
    return <Flex flexDirection={"column"} justifyContent={"space-between"}>{children}</Flex>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
    return (
        <Stack
        h={"full"}
            className='testimonial-content'
            bgColor={"whiteAlpha.800"}
            boxShadow={'lg'}
            maxWidth={"50vw"}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: "whiteAlpha.800",
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
    return (
        <Heading as={'h3'} fontSize={'xl'} color="blackAlpha.900">
            {children}
        </Heading>
    );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
    return (
        <Text
            textAlign={'center'}
            color={"blackAlpha.700"}
            fontSize={'sm'}>
            {children}
        </Text>
    );
};

const TestimonialAvatar = ({
    src,
    name,
    title,
}: {
    src: string;
    name: string;
    title: string;
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} mb={2} />
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    );
};

export default function WithSpeechBubbles() {
    return (
        <Flex
            w={'full'}
            h={"auto"}
        >
            <Container maxW={'7xl'} py={10} as={Stack} spacing={12}>
                <Stack spacing={0} align={'center'} >
                    <Heading>Team</Heading>
                </Stack>
                <Stack
                    align={'center'}
                    justify={'center'}
                    direction={{ base: 'column', md: 'column' }}>
                    <Flex
                        gap={"10px"}>
                        <Testimonial >
                            <TestimonialContent>
                                <TestimonialHeading>Efficient</TestimonialHeading>
                                <TestimonialText>
                                    Web dev here, enthusiast of any technology, always willing to work the smart way.
                                    You guys make my work easier so any feedback is welcomed!
                                </TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={tobello}
                                name={'tobello'}
                                title={'Web Developer'}
                            />
                        </Testimonial>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>Ambitious</TestimonialHeading>
                                <TestimonialText>
                                    Hi, I'm the blockchain developer, I will be bringing to life any art. Fully invested in this project and the blockchain technology.
                                </TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={luis}
                                name={'Luuiisitx'}
                                title={'Blockchain Developer'}
                            />
                        </Testimonial>
                    </Flex>
                    <Flex

                        gap={"10px"}>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>Authentic</TestimonialHeading>
                                <TestimonialText>
                                    I’m in charge of creating and organizing a unique community where you can find a comfortable environment while also keeping it professional.
                                </TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={wolf}
                                name={'Mr. Wolf'}
                                title={'Community Manager'}
                            />
                        </Testimonial>
                    </Flex>
                </Stack>
            </Container>
        </Flex>
    );
}