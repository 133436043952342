import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import { useState } from 'react';
import { ethers } from 'ethers';

function App() {
  const cronosRpc = "https://evm-cronos.crypto.org";
  const [_injectedProvider, _setInjectedProvider] = useState<ethers.providers.Web3Provider | undefined>(undefined);
  const [_address, _setAddress] = useState<String | undefined>(undefined);
  const [_cronosProvider, _serCronosProvider] = useState<ethers.providers.JsonRpcProvider>(new ethers.providers.JsonRpcProvider(cronosRpc));

  const appState = {
    cronosProvider: _cronosProvider,
    serCronosProvider: _serCronosProvider,
    injectedProvider: _injectedProvider,
    setInjectedProvider: _setInjectedProvider,
    address: _address,
    setAddress: _setAddress
  }

  return (
    <div className="App">
      <Router>
        <Header appState={appState}/>
        <Main appState={appState}/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
